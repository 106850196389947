import React, { useEffect, useRef, useState, useContext } from "react";
import Box from '@mui/material/Box';
import {getInspections} from '../../services/inspections';
import { DataGrid } from '@mui/x-data-grid';
import {UserProfileContext} from '../../contexts/UserProfileContext'

export function Inspections()
{
  const [inspections, setInspections] = useState([]);
  const shouldExecute = useRef(true);
  const { userProfile } = useContext(UserProfileContext);

  const columns = [
    { field: 'id', headerName: 'Datum', flex: 1},
    { field: 'playground', headerName: 'Spielplatz', flex: 1 },
    { field: 'type', headerName: 'Inspektionsart', flex: 1 },
    { field: 'user', headerName: 'durchgeführt von',flex: 1,},
    { field: 'defectcount', headerName: 'Mängel', type: 'number',flex: 0.5,},
    { field: 'report', headerName: 'Bericht', type: 'number',flex: 0.5, sortable: false,
      renderCell: (params) => (<a href={`${params.value}`}>Bericht</a>) },
  ];

  useEffect(() =>
  {
    if(shouldExecute.current && userProfile.valid)
    {
      shouldExecute.current = false;

      getInspections(userProfile.operatorUID).then(values => setInspections(values));
    }
  },[userProfile.operatorUID, userProfile.valid]);

  const rows = inspections.map(value => ({
    id: value.finish_time,
    playground: value.playground,
    type: value.type,
    user: value.user,
    defectcount: value.number_of_defects,
    report: value.report
}))

  return (
    <React.Fragment>
      <Box sx={{ '& > :not(style)': { m: 1 }, display: 'flex', justifyContent: 'flex-end'}}>
    </Box>
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 5 },
        },
      }}
      pageSizeOptions={[5, 10]}
    />
    </React.Fragment>
  );
}