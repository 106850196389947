import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";

export const getInspections = async (operatorUID) =>
{ 
  const querySnapshot = await getDocs(collection(db, `/operators/${operatorUID}/orders/`));
  const ed = [];

  querySnapshot.forEach((doc) =>
  {
    ed.push({id: doc.id, ...doc.data()});
  });
  
  console.log('Inspections', ed);
  return (ed);
}