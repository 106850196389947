import * as React from 'react';

import {useNavigate} from 'react-router-dom';

import AnalyticsIcon from '@mui/icons-material/Analytics';
import BungalowIcon from '@mui/icons-material/Bungalow';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

export function MainListItems()
{
  const navigate = useNavigate();

  return (
  <>
    <ListItemButton onClick={() => { navigate('/', { state: { text: "Übersicht" }}); }}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Übersicht" />
    </ListItemButton>
    <ListItemButton onClick={() => { navigate('/inspections', { state: { text: "Inspektionen" }}); }}>
      <ListItemIcon>
        <AnalyticsIcon />
      </ListItemIcon>
      <ListItemText primary="Inspektionen" />
    </ListItemButton>
    </>
  );
};

export function SecondaryListItems()
{
  const navigate = useNavigate();

  return (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Verwaltung
    </ListSubheader>
    <ListItemButton onClick={() => { navigate('/playgrounds', { state: { text: "Spielplätze" }}); }}>
      <ListItemIcon>
        <BungalowIcon />
      </ListItemIcon>
      <ListItemText primary="Spielplätze" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Mitarbeiter" />
    </ListItemButton>
  </React.Fragment>
  );
};