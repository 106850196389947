import React, { useEffect, useRef, useState, useContext  } from "react";
import { useLocation } from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import {UserProfileContext} from '../../contexts/UserProfileContext'

export default function Equipments()
{
  const navigate = useNavigate();
  const shouldExecute = useRef(true);
  const { userProfile } = useContext(UserProfileContext);
  
  // const playground = location.state.playground;
  const playground = useLocation().state.playground;
  const [equipments, setEquipment] = useState([]);
  
  useEffect(() =>
  {
    const getEquipment = async () =>
    { 
      const querySnapshot = await getDocs(collection(db, `/operators/`+userProfile.operatorUID+`/playgrounds/`+playground.id+`/equipment`));
      const ed = [];

      querySnapshot.forEach((doc) =>
      {
        ed.push({id: doc.id, ...doc.data()});
      });
      
      console.log('Equipment', ed);
      return (ed);
    }

    if(shouldExecute.current)
    {
      shouldExecute.current = false;

      getEquipment().then(values => setEquipment(values));
    }
  });
  
  const handleNewEquipment = () =>
  {
    navigate("/equipment/new", {
      state: { playground: playground }
    });
  };

  const handleEditEquipment = () =>
  {
    //todo
  };

  const handleDeleteEquipment = () =>
  {
    //todo
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ '& > :not(style)': { m: 1 }, display: 'flex', justifyContent: 'flex-end'}}>
        <Fab color="primary" aria-label="add" size="medium" onClick={() => {handleNewEquipment()}}>
          <AddIcon />
        </Fab>
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Bild</TableCell>
            <TableCell>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {equipments.map((equipment, index) => (
            <TableRow key={index}>
              <TableCell><img src={equipment.image} width="80" height="80"></img></TableCell>
              <TableCell>{equipment.name}</TableCell>
              <TableCell>
                <IconButton onClick={() => {handleEditEquipment()}} disabled><ModeEditIcon/></IconButton>
                <IconButton onClick={() => {handleDeleteEquipment()}}><DeleteIcon/></IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}