import * as React from 'react';

import Container from '@mui/material/Container';
import Grid      from '@mui/material/Grid';
import Paper     from '@mui/material/Paper';

import Deposits         from './Deposits';
import FutureOrders     from './FutureOrders';
import PastOrders       from './PastOrders';
import FocusPlaygrounds from './FocusPlaygrounds';

function DashboardContent()
{
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Focus Playgrounds */}
        <Grid item xs={12} md={8} lg={9}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 240, }} >
            <FocusPlaygrounds />
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={3}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 240, }} >
            <Deposits />
          </Paper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <PastOrders />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <FutureOrders />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export function Dashboard() {
  return <DashboardContent />;
}