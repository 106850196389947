import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Radio from '@mui/material/Radio';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function PlaygroundImages()
{
  const handleNewImage = () =>
  {
    //todo
  };

  const handleEditImage = () =>
  {
    //todo
  };

  const handleDeleteImage = () =>
  {
    //todo
  };


  let images = [
    {description: "Hubschrauber", url: "http://localhost:9199/v0/b/spielplatz-inspektion.appspot.com/o/playgrounds%2FMHELyWxfYjaxeE1c3Hu0%2F20220411_183818_7_11zon.jpg?alt=media&token=08c6378d-98df-4d22-a874-a6b6f3d01519"},
    {description: "Klettergrüst", url: "http://localhost:9199/v0/b/spielplatz-inspektion.appspot.com/o/playgrounds%2FMHELyWxfYjaxeE1c3Hu0%2F20220411_183901_4_11zon.jpg?alt=media&token=0e319790-553f-47c9-bbec-7b66ecc9ddc0"}
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ '& > :not(style)': { m: 1 }, display: 'flex', justifyContent: 'flex-end'}}>
        <Fab color="primary" aria-label="add" size="medium" onClick={() => {handleNewImage()}} disabled>
          <AddIcon />
        </Fab>
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Avatar</TableCell>
            <TableCell>Bild</TableCell>
            <TableCell>Beschreibung</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {images.map((image, index) => (
            <TableRow key={index}>
              <TableCell><Radio></Radio></TableCell>
              <TableCell><img src={image.url} width="100" height="100"></img></TableCell>
              <TableCell>{image.description}</TableCell>
              <TableCell>
                <IconButton onClick={() => {handleEditImage()}} disabled><ModeEditIcon/></IconButton>
                <IconButton onClick={() => {handleDeleteImage()}} disabled><DeleteIcon/></IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}