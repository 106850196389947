import React, {useState, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import TextField from '@mui/material/TextField';

import { collection, getDocs, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getDownloadURLForImage}  from "../../services/helper";
import {UserProfileContext} from '../../contexts/UserProfileContext'

export default function Equipment()
{
  const handleFileInput = useRef(null);
  const stripRef        = useRef(null);
  const location        = useLocation();
  const navigate        = useNavigate();
  const { userProfile } = useContext(UserProfileContext);

  const [name, setName]   = useState('');
  const [images, setImages]   = useState([]);

  const handleCancel = () =>
  {
    navigate(-1);
  };

  const handleOk = async () =>
  {
    //load image to Storage
    let fileEnding = images[0].name.split('.').pop();
    let uniqueFileName = Math.floor(Math.random() * Date.now())+"."+fileEnding;

    uploadBytes(ref(getStorage(), `operators/${userProfile.operatorUID}/playgrounds/${ location.state.playground.id }/${uniqueFileName}`), images[0]).then((result) =>
    {
      console.log('Uploaded defect image to storage!', result);

      getDownloadURLForImage(result.metadata.fullPath).then( (url) =>
      {
        const eq = {image: url, name: name};
        
        console.log(url);

        addDoc(collection(db, `/operators/${userProfile.operatorUID}/playgrounds/${ location.state.playground.id}/equipment`), eq).then((result) =>
        {
          //go back to equipment list
          navigate(-1);
        });
      });
    });
  };

  const handleNameChange = event =>
  {
    setName(event.target.value);
  };

  const handleImageChange = (event) =>
  {
    let strip = stripRef.current;
    const data = URL.createObjectURL(event.target.files[0]);

    const link = document.createElement("a");
    link.href = data;
    link.setAttribute("download", "myWebcam");
    link.innerHTML = `<img src='${data}' alt='thumbnail' width="100" height="100"/>`;
    strip.insertBefore(link, strip.firstChild);

    setImages(images => [...images, event.target.files[0]]);
  }

  const takePhoto = () =>
  {
    handleFileInput.current.click();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <TextField
          autoFocus
          required
          placeholder="Neues Spielgerät"
          margin="dense"
          id="name"
          label="Name"
          type="text"
          onChange={handleNameChange}
          value={name}
        />
        <div>
          <Box m={1} display="flex" justifyContent="center" alignItems="center">
            <IconButton onClick={() => takePhoto()} color="primary" aria-label="upload picture" component="label">
              <PhotoCamera />
            </IconButton>
          </Box>
          <label>
            <input style={{ display: "none" }} ref={handleFileInput} id="myFileInput" type="file" accept="image/*" capture="user" onChange={handleImageChange}></input>
          </label>
        <div ref={stripRef} />
        </div>
      <Button onClick={handleCancel}>Abbrechen</Button>
      <Button onClick={handleOk} disabled = {name === ''} >Fertig</Button>
    </Box>
  );
}