import * as React from 'react';
import Typography from '@mui/material/Typography';
import Title from './Title';

export default function Deposits() {
  return (
    <React.Fragment>
      <Title>Offene Mängel</Title>
      <Typography component="p" variant="h4">
        12
      </Typography>
      <Title> Durch Behebungsdauer</Title>
      <Typography component="p" variant="h4">
        2 Tage
      </Typography>
    </React.Fragment>
  );
}