import React, { useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MuiAppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';

import { auth } from "../../firebase";
import { Copyright } from '../copyright/Copyright';
import { Dashboard } from "../dashboard/Dashboard";
import  Equipment  from "../playgrounds/Equipment";
import { Inspections } from "../inspections/Inspections";
import { Login } from "../login/Login";
import { MainListItems, SecondaryListItems } from '../main/ListItems';
import { PlaygroundDetails } from "../playgrounds/PlaygroundDetails";
import { Playgrounds } from "../playgrounds/Playgrounds";
import UserProfileContextProvider from '../../contexts/UserProfileContext'

import "./App.css";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

const RouteRequiresLogin = ({ children }) =>
{
  const [user, loading ] = useAuthState(auth);

  if (!loading) {
    console.log(user);
    return user ? children : <Login />;
  }

  return null;
};

function App()
{
  const [open, setOpen] = React.useState(true);
  const [appBarText, setAppBarText] = React.useState("Übersicht");
  const location = useLocation();
  const [user, loading ] = useAuthState(auth);

  useEffect(() => {
    // execute on location change
    if(location.state !== null)
    {
      setAppBarText(location.state.text);
    }
}, [location]);

  const toggleDrawer = () =>
  {
    setOpen(!open);
  };

  return (
    <>
        <div>
        <RouteRequiresLogin>
        {user ? (<UserProfileContextProvider userID={user.uid}>
        <ThemeProvider theme={mdTheme}>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" open={open}>
              <Toolbar
                sx={{
                  pr: '24px', // keep right padding when drawer closed
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  sx={{
                    marginRight: '36px',
                    ...(open && { display: 'none' }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                >
                  {appBarText}
                </Typography>
                <IconButton color="inherit">
                  <Badge badgeContent={4} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
              <Toolbar
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  px: [1],
                }}
              >
                <IconButton onClick={toggleDrawer}>
                  <ChevronLeftIcon />
                </IconButton>
              </Toolbar>
              <Divider />
              <List component="nav">
                <MainListItems/>
                <Divider sx={{ my: 1 }} />
                <SecondaryListItems/>
              </List>
            </Drawer>
            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
              }}
            >
              <Toolbar />
              <Routes>
                <Route exact path="/"
                  element=
                  {
                    <Dashboard />
                  }
                >
                </Route>

                <Route path='/equipment/:id'
                  element=
                  {
                    <Equipment />
                  }
                />

                <Route path='/inspections'
                  element=
                  {
                    <Inspections />
                  }
                />

                <Route path='/playgrounds'
                  element=
                  {
                    <Playgrounds />
                  }
                />

                <Route path='/playgrounds/:id'
                  element=
                  {
                    <PlaygroundDetails />
                  }
                />

                <Route path="*"
                  element=
                  {
                    <Navigate to="/" state={{text:"Übersicht"}}/>
                  }
                />
              </Routes>
              <Copyright sx={{ pt: 4 }} />
            </Box>
          </Box>
        </ThemeProvider>
        </UserProfileContextProvider>) : ("")}
        </RouteRequiresLogin>
        </div>
    </>
  );
}

export default App;
