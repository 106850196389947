import React, { useEffect, useState, useRef, useContext } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import {useNavigate} from 'react-router-dom';
import {getInspections} from '../../services/inspections';
import {UserProfileContext} from '../../contexts/UserProfileContext'

export default function PastOrders()
{
  const [orders, setOrders] = useState([]);
  const shouldExecute = useRef(true);
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);

  useEffect(() =>
  {
    if(shouldExecute.current && userProfile.valid)
    {
      shouldExecute.current = false;

      getInspections(userProfile.operatorUID).then(values => setOrders(values));
    }
  }, [userProfile.operatorUID, userProfile.valid]);

  const handleOrderSelected = 
    (order) => 
  {
    navigate("/inspection/", {
      state: { order: order }
    });
  };

  return (
    <React.Fragment>
      <Title>Letzte Inspektionen</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Spielplatz</TableCell>
            <TableCell>Inspektionsart</TableCell>
            <TableCell align="right">Anzahl gefundener Mängel</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders && orders.map((order) => (
            <TableRow hover onClick={() => {handleOrderSelected(order)}} key={order.id} sx={order.number_of_defects ? {background: 'Tomato'}:{}}>
              <TableCell>{order.finish_time}</TableCell>
              <TableCell>{order.playground}</TableCell>
              <TableCell>{order.type}</TableCell>
              <TableCell align="right">{`${order.number_of_defects}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}