import { initializeApp } from "firebase/app";
import { getStorage, ref, connectStorageEmulator } from "firebase/storage";

import {
  getAuth,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  signInWithEmailAndPassword,
  connectAuthEmulator,
} from "firebase/auth";

import {
  getFirestore,
  collection,
  addDoc,
  connectFirestoreEmulator,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCayYv4sq1PxHXds1XbGr2gPNW-Ij8gH68",
  authDomain: "spielplatz-inspektion.firebaseapp.com",
  projectId: "spielplatz-inspektion",
  storageBucket: "spielplatz-inspektion.appspot.com",
  messagingSenderId: "526627912345",
  appId: "1:526627912345:web:0677e06bd07875e1875608",
  measurementId: "G-G19MRWY3TK",
};

const firebaseApp = initializeApp(firebaseConfig);

const storage = getStorage(firebaseApp);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

//initialize the emulators if not in production i.e. npm run build
if(process.env.NODE_ENV !== 'production')
{
  connectStorageEmulator(storage, "localhost", 9199);
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, 'localhost', 8080);
}

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};

// Create a storage reference from our storage service
const storageRef = ref(storage);
export default storageRef;
