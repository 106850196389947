import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';

// Generate Order Data
function createData(id, date, playground, inspectionType) {
  return { id, date, playground, inspectionType };
}

const rows  = [
  createData( 0, '1  Februar 2022', 'Bürgerhalle', 'Visuelle Routine-Inspektion' ),
  createData( 1, '5  Februar 2022', 'Pfefferloh' , 'Visuelle Routine-Inspektion' ),
  createData( 2, '1  März 2022', 'Bürgerhalle', 'Hauptinspektion'                ),
  createData( 3, '10 März 2022', 'Bürgerhalle', 'Visuelle Routine-Inspektion'    ),
  createData( 4, '20 März 2022', 'Pfefferloh' , 'Hauptinspektion, NJ'            ),
];

export default function FutureOrders()
{
  return (
    <React.Fragment>
      <Title>Anstehende Inspektionen</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Spielplatz</TableCell>
            <TableCell>Inspektionsart</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.playground}</TableCell>
              <TableCell>{row.inspectionType}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}