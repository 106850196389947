import storageRef from "../firebase";
import { ref, getDownloadURL } from "firebase/storage";

export function getDownloadURLForImage (gsurl)
{
  const gsReference = ref(storageRef, gsurl);

  console.log("GSURL:", gsurl);

  // Get the download URL
  return getDownloadURL(gsReference)
  .then((url) =>
  {
    console.log("URL:", url);
    return url;
  })
  .catch((error) => {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    console.log("errorcode", error.code);
    switch (error.code) {
      case 'storage/object-not-found':
        // File doesn't exist
        break;
      case 'storage/unauthorized':
        // User doesn't have permission to access the object
        break;
      case 'storage/canceled':
        // User canceled the upload
        break;

      // ...

      case 'storage/unknown':
        // Unknown error occurred, inspect the server response
        break;
    }
  }); 
}