import * as React from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Title from './Title';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  height: '170px', 
  color: theme.palette.text.secondary,
}));

export default function FocusPlaygrounds()
{
  return (
    <React.Fragment>
      <Title>Fokus Spielplätze</Title>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Item>
            Bürgerhalle
            <Table size="small">
              <TableBody>
                  <TableRow>
                    <TableCell>Mängel:</TableCell>
                    <TableCell sx={{background: 'Tomato'}}>4</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Inspektionen:</TableCell>
                    <TableCell sx={{background: 'LightGreen'}}></TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            Pfefferloh
            <Table size="small">
              <TableBody>
                  <TableRow>
                    <TableCell>Mängel:</TableCell>
                    <TableCell sx={{background: 'LightGreen'}}>0</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Inspektionen:</TableCell>
                    <TableCell sx={{background: 'Yellow'}}></TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </Item>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}