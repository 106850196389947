import React, { useEffect, useRef, useState, useContext } from "react";
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Fab from '@mui/material/Fab';
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import {useNavigate} from 'react-router-dom';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import {UserProfileContext} from '../../contexts/UserProfileContext'
import IconButton from '@mui/material/IconButton';


export function Playgrounds()
{
  const [playgrounds, setPlaygrounds] = useState([]);
  const shouldExecute = useRef(true);
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);

  const columns = [
    { field: 'icon', headerName: '', flex: 1, sortable: false, filterable: false,
             renderCell: (params) => (<Avatar alt="" src={params.value} sx={{ width: 56, height: 56 }}></Avatar>)},
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'street', headerName: 'Straße', flex: 1 },
    { field: 'zip', headerName: 'Postleitzahl', flex: 1 },
    { field: 'city', headerName: 'Ort', flex: 1 },
    { field: 'actions', headerName: '', flex: 1,
             renderCell: (params) => (<>
             <IconButton onClick={() => {handleEditPlayground(params.value)}}><ModeEditIcon/></IconButton>
             <IconButton onClick={() => {handleDeletePlayground(params.value)}} disabled ><DeleteIcon/></IconButton>
             </>)},
  ];

  useEffect(() =>
  {
    if(shouldExecute.current)
    {
      shouldExecute.current = false;

      getPlaygrounds().then(values => setPlaygrounds(values));
    }
  }, []);

  const rows = playgrounds.map(playground => ({
    id:     playground.id,
    icon:   playground.avatar,
    name:   playground.name,
    street: playground.address.street,
    zip:    playground.address.zip,
    city:   playground.address.city,
    actions:playground,
  }))

  const getPlaygrounds = async () =>
  { 
    const querySnapshot = await getDocs(collection(db, `/operators/${userProfile.operatorUID}/playgrounds/`));
    const ed = [];

    querySnapshot.forEach((doc) =>
    {
      ed.push({id: doc.id, ...doc.data()});
    });
    
    console.log('Playgrounds', ed);
    return (ed);
  }

  const handleEditPlayground = 
    (playground) => 
  {
    navigate("/playgrounds/"+playground.id, {
      state: { playground: playground,
               text: `Spielplatz ${playground.name}`}
    });
  };

  const handleViewPlayground = 
    (playground) => 
  {
    navigate("/playgrounds/"+playground.id, {
      state: { playground: playground }
    });
  };

  const handleDeletePlayground = 
    (playground) => 
  {
    //todo
  };

  const handleNewPlayground = () =>
  {
    //todo
  };

  return (
    <React.Fragment>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnSelector
        disableRowSelectionOnClick={true}
        autoHeight
        hideFooterPagination={true}
        hideFooter
      />
      <Box sx={{ '& > :not(style)': { m: 1 }, display: 'flex', justifyContent: 'flex-end'}}>
        <Fab color="primary" aria-label="add" size="medium" onClick={() => {handleNewPlayground()}} disabled>
          <AddIcon />
        </Fab>
      </Box>
    </React.Fragment>
  );
}