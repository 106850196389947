import * as React from 'react';
import { useLocation } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

function valuetext(value)
{
  return `${value}°C`;
}

export default function PlaygroundBasics()
{
  const location = useLocation();
  const playground = location.state.playground;

  const [value, setValue] = React.useState([1, 12]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <TextField
            required
            id="outlined-required"
            label="Name"
            defaultValue={playground.name}
            size="small"
            />
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <TextField
              required
              id="street"
              label="Straße"
              defaultValue={playground.address.street}
              size="small"
            />
            <TextField
              required
              id="number"
              label="Hausnummer"
              defaultValue={playground.address.housenumber}
              size="small"
            />
            <TextField
              required
              id="zip"
              label="Postleitzahl"
              defaultValue={playground.address.zip}
              size="small"
            />
            <TextField
              required
              id="city"
              label="Ort"
              defaultValue={playground.address.city}
              size="small"
            />
          </Item>
        </Grid>
        <Grid item xs={2}>
          <Item>
            <Typography gutterBottom>Altersklasse</Typography>
            <Slider
              getAriaLabel={() => 'Altersklasse'}
              value={value}
              onChange={handleChange}
              valueLabelDisplay="on"
              getAriaValueText={valuetext}
              step={1}
              marks
              min={0}
              max={18}
              size="small"
            />
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">Lage</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="outside"
              >
                <FormControlLabel value="inside" control={<Radio />} label="Drinnen" />
                <FormControlLabel value="outside" control={<Radio />} label="Draußen" />
              </RadioGroup>
            </FormControl>
          </Item>
        </Grid>
        <Grid item xs={8}>
          <Item>xs=8</Item>
        </Grid>
      </Grid>
    </Box>
  );
}